<template>
<div>
  <dx-data-grid
    id="shippingItemsContentGrid"
    ref="shippingItemsContentRef"
    :height="shippingItemsContentHeight"
    :data-source="batchItems"
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :show-column-lines="true"
    :show-row-lines="true"
    :show-borders="true"
    :row-alternation-enabled="false"
    :hover-state-enabled="false"
    :customize-columns="customizeColumns"
    @content-ready="onContentReady"
    @toolbar-preparing="onToolbarPreparing($event)"
  >
    <dx-paging :enabled="false" />
    <dx-column data-field="catalogItem" cell-template="item-cell-template" width="680" />
    <template #item-cell-template="{ data }">
      <item-info
        :id="data.row.data.id"
        :catalog-item="data.value"
        :title="data.row.data.title"
        :price-list="data.row.data.priceList"
        :pallet-id="data.row.data.palletId"
        :asin="data.row.data.asin"
        :rank="data.row.data.amzRank"
        :upc="data.row.data.upc"
        :msku="data.row.data.msku"
        :fnsku="data.row.data.fnsku"
        :expiration-date="data.row.data.expiration"
        :image-url="data.row.data.smallImage"
        :large-image-url="data.row.data.largeImage"
        :product-type="data.row.data.productType"
        :sale-price="data.row.data.salePrice"
        :cost-price="data.row.data.itemCost"
        :is-printable="true"
        :is-truncated="true"
        @on-emit-catalog-item-info="onEmitCatalogItemInfo"
      />
    </template>
    <!-- <dx-column data-field="itemCondition" caption="Condition" width="80" /> -->
    <dx-column data-field="quantity" caption="Qty" alignment="left" width="80" cell-template="quantity-cell-template" />
    <template #quantity-cell-template="{ data }">
        <div class="d-flex flex-row mr-half">
          <div v-b-tooltip.hover="{ variant: 'info' }" title="Quantity" class="align-self-center bg-success rounded-left px-half badge-item" style="width: 32px;">
            {{ data.value }}
          </div>
          <div class="bg-success rounded-right px-half badge-item">
            <b-icon icon="pencil-square" scale="1.6" class="align-self-center text-white cursor-pointer" style="width: 32px;" @click="onClickEditQuantity($event,data)" />
          </div>
        </div>
    </template>
    <dx-column data-field="itemBoxMap" caption="Boxing" cell-template="box-cell-template" css-class="dx-column-vertical-middle py-0" />
    <template #box-cell-template="{ data }">
      <shipping-item-content-box-info :box-map="data.data" />
    </template>
    <!--region    ::DataGrid toolbar -->
    <template #toolbarFilters>
      <div class="d-flex flex-row align-items-center px-1">
        <label for="selectedShipmentBatch" class="form-label mr-half">Batches</label>
        <dx-util-select-box
          id="selectedShipmentBatch"
          v-model="selectedShipmentBatchId"
          :data-source="shipmentBatches"
          width="auto"
          display-expr="text"
          value-expr="value"
          class="mr-1"
          @value-changed="searchByFilter"
        />
        <dx-util-text-box
          id="queryShipmentBatch"
          v-model="queryShipmentBatch"
          mode="text"
          placeholder="Search"
          class="mr-1"
          :show-clear-button="true"
          @key-down="searchQueryByFilter"
        />
        <label for="selectedShipmentBatchBoxes" class="form-label mr-1">Boxes</label>
        <dx-util-select-box
          id="scanBoxingBoxId"
          v-model="scanBoxing.boxId"
          :data-source="scanBoxing.boxes"
          display-expr="text"
          value-expr="value"
          class="mr-1"
        />
        <label for="scanBoxingFNSKU" class="form-label mr-1">FNSKU</label>
        <dx-util-text-box
          id="scanBoxingFNSKU"
          ref="scanBoxingFNSKUTextBoxRef"
          v-model="scanBoxing.fnsku"
          mode="text"
          placeholder="Scan FNSKU"
          class="mr-1"
          :show-clear-button="true"
          :read-only="isReadOnlyWaitNewScan"
          @enter-key="scanBoxInfo($event)"
        />
        <dx-util-check-box
          id="boxAll"
          v-model="scanBoxing.boxAll"
          text="Add All"
          class="mr-1"
        />
        <dx-util-button
          type="success"
          icon="save"
          hint="Save"
          @click="scanBoxInfoSave"
        />
      </div>
    </template>
    <template #toolbarAfterFilters>
      <div class="d-flex flex-row align-items-center pr-1" style="height: 36px;">
        <div class="align-self-center">
          <dx-util-check-box
            v-model="showBoxedItems"
            text="Show Boxed Items"
            @value-changed="getBoxedItems"
          />
        </div>
      </div>
    </template>
    <!--endregion    ::DataGrid toolbar -->
  </dx-data-grid>
  <!--Begin:: Create Or Update Form-->
    <dx-util-popup
      ref="updateItemQuantityPopupRef"
      :show-close-button="true"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :width="800"
      :height="480"
      title="Update Item Quantity"
      @hiding="onUpdateItemQuantityPopup"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="before" :options="saveButtonOptions" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
      <form method="post" @submit="onSaveItemQuantitySubmit">
        <dx-util-form ref="updateItemQuantityFromRef" :form-data="updateItemQuantityFrom" :col-count="1" :show-colon-after-label="false" label-location="top">
          <template #information-template="{}">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 px-0">
                  Item Quantity Update
                </div>
              </div>
            </div>
          </template>
          <template #remove-button-template="{}">
            <div>
              <dx-util-button
                icon="trash"
                hint="Remove"
                type="danger"
                styling-mode="contained"
                @click="onRemoveItem"
              />
            </div>
          </template>
          <dx-util-simple-item template="information-template" />
          <dx-util-group-item :col-count="6">
            <dx-util-item data-field="shipmentBatchId" :col-span="2" editor-type="dxSelectBox" :editor-options="shipmentBatchIdOptions" :label="{text: 'Batch Name'}">
              <dx-util-required-rule message="Batch is required" />
            </dx-util-item>
            <dx-util-item data-field="currentQuantity" editor-type="dxNumberBox" :editor-options="currentQuantityOptions" :label="{text: 'Current Qty'}" />
            <dx-util-item data-field="newQuantity" editor-type="dxNumberBox" :editor-options="newQuantityOptions" :label="{text: 'New Qty'}">
              <dx-util-required-rule message="New Quantity is required" />
            </dx-util-item>
            <dx-util-item data-field="skipAmazonUpdate" editor-type="dxCheckBox" :label="{text: 'Skip Amazon Update'}" />
            <dx-util-item template="remove-button-template" :label="{text: 'Remove'}" />
          </dx-util-group-item>
        </dx-util-form>
      </form>
    </dx-util-popup>
  <!--End:: Create Or Update Form-->
  <catalog-item :asin="selectedAsin" @on-emit-close="selectedAsin=null" />
</div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

import isNaN from 'lodash/isNaN'
import { emitter } from '@/libs/bus'
import { awaiter } from '@/libs/utils'
import { FBAEvents } from '@/libs/constants/events.const'
import fbaShipmentService from '@/http/requests/outbound/fbaShipmentService'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { Notify } from '@robustshell/utils/index'
import store from '@/store'
import ItemInfo from './ItemInfo.vue'
import ShippingItemContentBoxInfo from './ShippingItemContentBoxInfo.vue'
import fbaShipment from '../fbaShipment.mixin'

export default {
  components: {
    'item-info': ItemInfo,
    'shipping-item-content-box-info': ShippingItemContentBoxInfo,
    'catalog-item': () => import('@/views/apps/admin/maintenance/CatalogItem.vue'),
  },
  mixins: [GridBase, fbaShipment],
  props: {
    batchId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      batchItems: [],
      selectedShipmentBatchId: this.batchId,
      shipmentBatches: [],
      queryShipmentBatch: '',
      showBoxedItems: false,
      updateItemQuantityFrom: {
        shipmentPlanId: null,
        shipmentBatchId: null,
        itemId: null,
        currentQuantity: 0,
        skipAmazonUpdate: false,
        newQuantity: 0,
        deleted: false,
      },
      shipmentBatchItemList: [],
      selectedAsin: null,
      scanBoxing: {
        fnsku: '',
        boxAll: false,
        boxId: 0,
        boxes: [],
      },
      isReadOnlyWaitNewScan: false,
    }
  },
  computed: {
    batcBoxes() {
      const items = [...store.getters['batchBoxes/getBatchBoxes']]
      return items
    },
    searchItemPopup() {
      const popupIns = this.$refs.searchItemPopupRef.instance
      return popupIns
    },
    scanBoxingFNSKUTextBoxInstance() {
      return this.$refs.scanBoxingFNSKUTextBoxRef.instance
    },
    shipmentBatchIdOptions() {
      return {
        dataSource: this.shipmentBatchItemList,
        valueExpr: 'shipmentBatchId',
        displayExpr: 'shipmentBatchName',
        searchEnabled: false,
        dropDownOptions: {
          height: 150,
        },
        onValueChanged: e => {
          const shipmentBatchId = e.value
          this.setSelectedShipmentItemQuantity(shipmentBatchId)
        },
      }
    },
    currentQuantityOptions() {
      return {
        readOnly: true,
        value: this.updateItemQuantityFrom.currentQuantity,
      }
    },
    newQuantityOptions() {
      return {
        min: 0,
        format: '###0',
      }
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: () => {
          this.onSaveItemQuantitySubmit()
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          const popup = this.$refs.updateItemQuantityPopupRef.instance
          popup.hide()
        },
      }
    },
  },
  watch: {
    batchId: {
      immediate: true,
      handler(current, previous) {
        if (current && current > 0) {
          this.setShippingItemsContentHeight()
          this.setSelectedBatchItem()
          this.getSelectedBatches(current)
        }
      },
    },
    queryShipmentBatch(current) {
      if (!current || current === '') {
        this.setSelectedBatchItem()
      }
    },
    batcBoxes: {
      immediate: true,
      handler() {
        this.updateBoxes()
      },
    },
  },
  created() {
    emitter.on(FBAEvents.BOX_FILTER_SELECTED_FNSKU.event, payload => {
      this.queryShipmentBatch = payload
      this.setSelectedBatchItem()
    })
  },
  methods: {
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })

      const toolbarAfterItems = e.toolbarOptions.items
      toolbarAfterItems.unshift({
        location: 'after',
        template: 'toolbarAfterFilters',
      })
    },
    getSelectedBatches(batchId) {
      const parsed = parseInt(batchId, 10)
      if (isNaN(parsed)) { return }
      fbaShipmentService.getBatchDataByParentId(parsed).then(result => {
        this.shipmentBatches.splice(0, this.shipmentBatches.length)
        this.shipmentBatches.push({
          value: batchId,
          text: 'All Batches',
        })
        const { data } = result
        data.forEach(item => {
          this.shipmentBatches.push({
            value: item.id,
            text: `${item.destCode} - ${item.shipmentId}`,
          })
        })
      })
    },
    setSelectedBatchItem() {
      const parsed = parseInt(this.selectedShipmentBatchId, 10)
      if (isNaN(parsed)) { return }
      const filters = {
        companyId: 0,
        accountNo: '',
        q: this.queryShipmentBatch,
        storeId: 0,
        warehouseId: 0,
        beginDate: null,
        endDate: null,
        tenantType: 'SERVING',
        batchId: parsed,
        status: 'ALL',
        batchStatus: 'all',
        showBoxedItems: this.showBoxedItems,
      }
      fbaShipmentService.getBatchParentItemsByQuery(filters).then(result => {
        this.batchItems.splice(0, this.batchItems.length)
        result.data.forEach(item => {
          const batchItem = {
            title: item.title,
            asin: item.asin,
            upc: item.upc,
            fnsku: item.fnsku,
            msku: item.msku,
            batchParentId: item.batchParentId,
            catalogItem: item.catalogItem,
            priceList: item.priceList,
            companyId: item.companyId,
            countInBundle: item.countInBundle,
            countPerBundle: item.countPerBundle,
            damagedCount: item.damagedCount,
            expirationDate: item.expirationDate,
            id: item.id,
            itemAction: item.itemAction,
            itemCondition: item.itemCondition,
            itemCost: item.itemCost,
            itemSource: item.itemSource,
            notes: item.notes,
            orderNumber: item.orderNumber,
            packType: item.packType,
            planId: item.planId,
            productId: item.productId,
            quantity: item.quantity,
            receivedCount: item.receivedCount,
            reservedCount: item.reservedCount,
            salePrice: item.salePrice,
            shippedCount: item.shippedCount,
            sortedCount: item.sortedCount,
            storeId: item.storeId,
            storeName: item.storeName,
            supplier: item.supplier,
            taxCode: item.taxCode,
            tracking: item.tracking,
            warehouseId: item.warehouseId,
            itemBoxMap: item.itemBoxMap,
            amzRank: item.amzRank,
            smallImage: item.smallImage,
            largeImage: item.largeImage,
            productType: item.productType,
            palletId: item.palletId,
          }
          this.batchItems.push(batchItem)
        })
      })
    },

    getBoxedItems() {
      this.setSelectedBatchItem()
    },

    searchByFilter(e) {
      this.setDefaulScanBoxing()
      const parsed = parseInt(e.value, 10)
      this.selectedShipmentBatchId = parsed
      this.setSelectedBatchItem()
      this.getShipmentBatchBoxes()
    },
    updateBoxes() {
      if (this.selectedShipmentBatchId !== this.batchId) {
        this.getShipmentBatchBoxes()
      }
    },
    getShipmentBatchBoxes() {
      if (this.selectedShipmentBatchId === this.batchId) {
        this.scanBoxing.boxId = 0
        this.scanBoxing.boxes.length = 0
        return
      }
      fbaShipmentService.findAllBoxeDataByBatchId(this.selectedShipmentBatchId).then(result => {
        this.scanBoxing.boxes.length = 0
        result.reverse()
        if (result.length > 0) {
          this.scanBoxing.boxId = result[0].id
        }
        result.forEach(item => {
          this.scanBoxing.boxes.push({
            value: item.id,
            text: `B${item.boxOrder}`,
          })
        })
      })
    },
    setDefaulScanBoxing() {
      this.scanBoxing.fnsku = ''
      this.scanBoxing.boxAll = false
      this.scanBoxing.boxId = 0
    },
    scanBoxInfoSave() {
      this.isReadOnlyWaitNewScan = false
      this.handleScanBoxing()
    },
    scanBoxInfo(e) {
      this.scanBoxInfo.fnsku = e.value
      this.isReadOnlyWaitNewScan = true
      e.event.target.value = ''
      this.handleScanBoxing()
    },
    handleScanBoxing() {
      const { fnsku, boxId, boxAll } = this.scanBoxing
      if (fnsku === '') {
        Notify.warning('Please scan FNSKU')
        return
      }
      const parsed = parseInt(boxId, 10)
      if (isNaN(parsed)) {
        Notify.warning('Please select a box')
        return
      }
      const batchId = this.selectedShipmentBatchId
      if (batchId === this.batchId) {
        Notify.warning('Please select a batch')
        return
      }
      const data = {
        fnsku,
        boxId: parsed,
        boxAll,
        batchId,
      }
      fbaShipmentService.scanInfoBox(data)
        .then(() => {
          this.isReadOnlyWaitNewScan = false
          Notify.success('Scanned successfully')
        })
        .then(() => {
          this.setDefaulScanBoxing()
        })
        .then(() => {
          this.setSelectedBatchItem()
          this.getShipmentBatchBoxes()
        })
        .then(() => {
          awaiter(500).then(() => {
            emitter.emit(FBAEvents.UPDATE_SHIPMENT_BATCHES_AFTER_ADDED_BOX_ITEM.event)
            emitter.emit(FBAEvents.BOX_UPDATE_AFTER_SHIP_ACTION.event, FBAEvents.BOX_UPDATE_AFTER_SHIP_ACTION.action)
          })
        })
        .then(() => {
          this.scanBoxingFNSKUTextBoxInstance.focus()
        })
        .catch(() => {
          this.isReadOnlyWaitNewScan = false
        })
    },
    searchQueryByFilter(e) {
      if (e.event.keyCode === 27) {
        this.query = ''
        this.queryShipmentBatch = ''
        this.setSelectedBatchItem()
        return
      }
      if (e.event.keyCode !== 13) return
      this.queryShipmentBatch = e.event.target.value
      this.setSelectedBatchItem()
    },

    /**
     * Item Quantity Update
     */
    onClickEditQuantity(e, row) {
      const popup = this.$refs.updateItemQuantityPopupRef.instance
      popup.show()
      const { data } = row
      this.updateItemQuantityFrom.shipmentPlanId = data.batchParentId
      this.updateItemQuantityFrom.itemId = data.id
      this.shipmentBatchItemList.splice(0, this.shipmentBatchItemList.length)
      this.shipmentBatchItemList = data.itemBoxMap.itemBatchList.map(item => ({
        shipmentBatchId: item.shipmentBatchId,
        shipmentBatchName: `${item.dest} - ${item.shipmentBatchName}`,
        totalItemQuantity: item.totalItemQuantity,
      }))
    },
    setSelectedShipmentItemQuantity(value) {
      if (value) {
        const selected = this.shipmentBatchItemList.find(p => p.shipmentBatchId === value)
        this.updateItemQuantityFrom.currentQuantity = selected.totalItemQuantity
      }
    },
    onUpdateItemQuantityPopup() {
      this.updateItemQuantityFrom = {
        shipmentPlanId: null,
        shipmentBatchId: null,
        itemId: null,
        currentQuantity: 0,
        newQuantity: 0,
        deleted: false,
      }
      this.shipmentBatchItemList.splice(0, this.shipmentBatchItemList.length)
      this.selectedShipmentBatchId = this.batchId
      this.setSelectedBatchItem()
    },
    onSaveItemQuantitySubmit(e) {
      const popup = this.$refs.updateItemQuantityPopupRef.instance
      const form = this.$refs.updateItemQuantityFromRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        fbaShipmentService.updateBatchItemQuantity(this.updateItemQuantityFrom).then(() => {
          popup.hide()
        })
      }
    },
    onRemoveItem() {
      this.updateItemQuantityFrom = {
        ...this.updateItemQuantityFrom,
        newQuantity: 0,
        deleted: true,
      }

      const popup = this.$refs.updateItemQuantityPopupRef.instance
      const form = this.$refs.updateItemQuantityFromRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        fbaShipmentService.updateBatchItemQuantity(this.updateItemQuantityFrom)
          .then(() => {
            popup.hide()
          })
          .then(() => {
            this.setSelectedBatchItem()
          })
      }
    },
    onEmitCatalogItemInfo(value) {
      this.selectedAsin = value
    },
  },
}
</script>

<style lang="scss">

</style>
