<template>
  <div class="d-flex flex-row justify-content-start">
    <div class="d-flex flex-column justify-content-start flex-grow-1 mr-half" :class="borderCss">
      <div v-for="(batch, index) in itemBatches.batches" :key="batch.rowId" class="d-flex flex-row py-half" :class="index !==0 ? 'border-top' : ''">
        <div class="d-flex flex-row mr-half">
          <div v-b-tooltip.hover="{ variant: 'info' }" title="Destination" class="align-self-center bg-secondary rounded-left px-half badge-item" style="width: 64px;">
            {{ batch.dest }}
          </div>
          <div class="align-self-center bg-warning rounded-right px-half text-black badge-item" style="width: 120px;">
            {{ batch.shipmentBatchName }}
          </div>
        </div>
        <div class="align-self-center mr-half">
          <div v-b-tooltip.hover="{ variant: 'info' }" title="Total Item Quantity" class="rounded bg-success text-center badge-item" style="width: 36px !important;">
            {{ batch.totalItemQuantity }}
          </div>
        </div>
        <div class="align-self-center mr-half">
          <div v-b-tooltip.hover="{ variant: 'info' }" title="Remaining Item Quantity" class="rounded text-center badge-item" :class="batch.remainingQuantity === 0 ? 'bg-warning' : 'bg-danger'" style="width: 36px !important;">
            {{ batch.remainingQuantity }}
          </div>
        </div>
        <div class="align-self-center mr-half">
          <dx-util-button
            type="default"
            hint="Move all items to the selected box"
            icon="bi bi-box-fill"
            @click="onAddBoxAllItemQuantity($event, batch)"
          />
        </div>
        <div class="align-self-center">
          <dx-util-select-box
            :id="batch.rowId"
            :ref="batch.rowId"
            :data-source="batch.boxes"
            :selected-item="batch.selectedBox"
            :value="batch.selectedBoxId"
            :show-clear-button="false"
            :width="90"
            display-expr="name"
            value-expr="id"
          />
        </div>
        <div class="align-self-center mr-half">
          <dx-util-button
            type="default"
            hint="Add Selected Box"
            icon="add"
            @click="onAddBoxItemQuantity($event, batch)"
          />
        </div>
        <div class="row ml-half w-100">
        <div v-for="boxItemQuantity in batch.boxItemQuantityList" :key="boxItemQuantity.id" class="d-flex flex-row col-2 bg-success justify-content-between rounded-left pl-half pr-0 mr-1 mb-half">
          <div class="align-self-center">
            {{ boxItemQuantity.boxName }}
          </div>
          <div class="ml-auto align-self-center">
            <dx-util-number-box
              v-model="boxItemQuantity.itemQuantity"
              :read-only="boxItemQuantity.isDisabled"
              :min="0"
              :max="batch.totalItemQuantity"
              :show-spin-buttons="false"
              :width="40"
              :element-attr="{class: 'rounded-0'}"
              @value-changed="onAddedItemQuantity($event, batch)"
            />
          </div>
        </div>
        </div>
      </div>
    </div>
    <div class="align-self-center">
      <div class="d-flex justify-content-center">
        <div>
          <dx-util-button
            :visible="boxEditing"
            type="success"
            hint="Save box quantites"
            icon="save"
            @click="onSaveBoxItem"
          />
        </div>
        <div>
          <dx-util-button
            :visible="!boxEditing"
            type="normal"
            class="btn-warning"
            hint="Edit box quantites"
            icon="edit"
            @click="onEditBoxItem"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { emitter } from '@/libs/bus'
import { FBAEvents } from '@/libs/constants/events.const'
import store from '@/store'
import filter from 'lodash/filter'
import head from 'lodash/head'
import find from 'lodash/find'
import map from 'lodash/map'
import remove from 'lodash/remove'
import orderBy from 'lodash/orderBy'
import { awaiter } from '@/libs/utils'
import { Notify } from '@robustshell/utils/index'
import { v4 as uuidv4 } from 'uuid'
import fbaShipmentService from '@/http/requests/outbound/fbaShipmentService'

export default {
  components: {},
  props: {
    boxMap: {
      type: Object,
      required: true,
    },
  },
  data() {
    const { itemBoxMap } = this.boxMap
    return {
      itemBoxMap,
      itemBatches: {
        parentItemId: null,
        batches: [],
      },
      boxes: [],
      selectedItem: null,
      boxEditing: false,
    }
  },
  computed: {
    batcBoxes() {
      const items = [...store.getters['batchBoxes/getBatchBoxes']]
      return items
    },
    borderCss() {
      return this.itemBatches.batches.length > 1 ? 'border-right' : ''
    },
  },
  watch: {
    batcBoxes: {
      immediate: true,
      handler() {
        this.updateBoxes()
      },
    },
  },
  mounted() {
    this.setItemBatchList()
  },
  methods: {
    updateBoxes() {
      this.itemBatches.batches.forEach(item => {
        let boxes = filter(this.batcBoxes, p => p.parentId === item.shipmentBatchId)
        boxes = orderBy(boxes, 'id', 'desc')
        item.boxes = boxes
        const initialBox = head(boxes)
        item.selectedBox = initialBox || null
        item.selectedBoxId = initialBox ? initialBox.id : null
      })
    },
    setItemBatchList() {
      const itemBatchList = [...this.itemBoxMap.itemBatchList]
      this.itemBatches.batches.splice(0, this.itemBatches.batches.length)
      this.itemBatches.parentItemId = this.itemBoxMap.parentItemId
      itemBatchList.forEach(item => {
        const boxes = filter(this.batcBoxes, p => p.parentId === item.shipmentBatchId)
        const initialBox = head(boxes)
        const subTotal = this.calculateSubTotal(item)
        const rowId = uuidv4()
        const boxItemQuantityList = map(item.boxItemQuantityList, p => ({
          boxId: p.boxId,
          boxName: p.boxName,
          itemQuantity: p.itemQuantity,
          isDisabled: true,
        }))
        const batchItem = {
          rowId,
          remainingQuantity: item.totalItemQuantity - subTotal,
          shipmentBatchId: item.shipmentBatchId,
          shipmentBatchName: item.shipmentBatchName,
          dest: item.dest,
          totalItemQuantity: item.totalItemQuantity,
          boxes: boxes,
          selectedBox: initialBox || null,
          selectedBoxId: initialBox ? initialBox.id : null,
          boxItemQuantityList: orderBy(boxItemQuantityList, 'boxId', 'desc'),
        }
        this.itemBatches.batches.push(batchItem)
      })
    },
    onAddBoxItemQuantity(e, batch) {
      if (batch.remainingQuantity <= 0) return
      const selectBox = this.$refs[batch.rowId][0].instance
      const selectedBox = selectBox.option('selectedItem')
      if (selectedBox) {
        const batchBox = {
          boxId: selectedBox.id,
          boxName: selectedBox.name,
          itemQuantity: 0,
          isDisabled: false,
        }
        const exists = find(batch.boxItemQuantityList, p => p.boxId === batchBox.boxId)
        if (!exists) {
          batch.boxItemQuantityList.push(batchBox)
        } else {
          Notify.warning('This box has already been added')
        }
      } else {
        Notify.warning('Please select a box')
      }
    },
    onAddBoxAllItemQuantity(e, batch) {
      const selectBox = this.$refs[batch.rowId][0].instance
      const selectedBox = selectBox.option('selectedItem')
      if (selectedBox) {
        const batchBox = {
          boxId: selectedBox.id,
          boxName: selectedBox.name,
          itemQuantity: batch.remainingQuantity,
          isDisabled: true,
        }
        const exists = find(batch.boxItemQuantityList, p => p.boxId === batchBox.boxId)
        if (!exists) {
          batch.boxItemQuantityList.push(batchBox)
          this.onAddedItemQuantity(e, batch)
        } else {
          Notify.warning('This item has already been added to the selected box. Please edit the quantity in the box if necessary.')
        }
      } else {
        Notify.warning('Please select a box')
      }
    },
    onAddedItemQuantity(e, batch) {
      const subTotal = this.calculateSubTotal(batch)
      batch.remainingQuantity = batch.totalItemQuantity - subTotal
      if (subTotal > batch.totalItemQuantity) {
        Notify.error('Item Quantity can not exceed the total quantity')
        return
      }
      this.onSaveBoxItem(e)
      awaiter(500).then(() => {
        emitter.emit(FBAEvents.UPDATE_SHIPMENT_BATCHES_AFTER_ADDED_BOX_ITEM.event)
        emitter.emit(FBAEvents.BOX_UPDATE_AFTER_SHIP_ACTION.event, FBAEvents.BOX_UPDATE_AFTER_SHIP_ACTION.action)
      })
    },
    calculateSubTotal(batch) {
      const { boxItemQuantityList } = batch
      if (boxItemQuantityList.length > 0) {
        const quantities = boxItemQuantityList.map(p => (p.itemQuantity ? p.itemQuantity : 0))
        const reducer = (accumulator, curr) => accumulator + curr
        return quantities.reduce(reducer)
      }
      return 0
    },
    onEditBoxItem(e) {
      this.boxEditing = true
      this.itemBatches.batches.forEach(batch => {
        map(batch.boxItemQuantityList, p => {
          p.isDisabled = false
        })
      })
    },
    async onSaveBoxItem(e) {
      const { parentItemId, batches } = this.itemBatches
      const itemBoxMap = {
        parentItemId: parentItemId,
        itemBatchList: [],
      }
      batches.forEach(batch => {
        map(batch.boxItemQuantityList, p => {
          p.isDisabled = true
        })
        const boxItemQuantityList = batch.boxItemQuantityList.map(item => {
          const val = item.itemQuantity ? item.itemQuantity : 0
          const obj = {
            boxId: item.boxId, boxName: item.boxName, itemQuantity: val,
          }
          return obj
        })
        const item = {
          shipmentBatchId: batch.shipmentBatchId,
          shipmentBatchName: batch.shipmentBatchName,
          dest: batch.dest,
          totalItemQuantity: batch.totalItemQuantity,
          boxItemQuantityList: boxItemQuantityList,
        }
        const boxes = filter(this.batcBoxes, p => p.parentId === item.shipmentBatchId)
        itemBoxMap.itemBatchList.push(item)
      })
      try {
        this.boxEditing = false
        await fbaShipmentService.assignBatchParentItemToBoxes(itemBoxMap)
        Notify.success('Box item has been saved successfully')
        map(this.itemBatches.batches, p => {
          remove(p.boxItemQuantityList, c => typeof c.itemQuantity === 'undefined' || c.itemQuantity === '' || c.itemQuantity === null || c.itemQuantity === 0)
        })
      } catch (error) {
        Notify.error(error)
      }
    },
  },
}
</script>

<style lang="scss">

</style>
